<footer class="footer" id="footer" role="contentinfo">
    <div class="inner-footer grid-x grid-margin-x grid-padding-x">
        <div id="footer1" class="sidebar footer small-12 medium-6 large-6 cell" role="complementary">
            <p><img class="alignleft wp-image-3141 size-full" src="https://sfo2.digitaloceanspaces.com/cdn-multicoat-com/wp-content/uploads/2019/03/02231901/v2GIthKg.png" alt="" width="191" height="218"></p>
            <h3 style="text-align: center;">Expect the Highest Caliber of Products</h3>
            <p style="text-align: center;"><a class="button-catalog" href="https://sfo2.digitaloceanspaces.com/cdn-multicoat-com/wp-content/uploads/2019/04/02231813/Product-Catalog-16-Page.pdf" target="_blank" rel="noopener">Download Product Catalog</a></p>
            <p style="text-align: center;"><a class="button-catalog" href="https://www.multicoat.com/addditional-resources/#color-charts">View Color Charts</a></p>
            <p style="text-align: center;"><a class="button-catalog" href="https://www.multicoat.com/addditional-resources/#arch-specs">Architectural Specifications</a></p>
            <p style="text-align: center;"><a class="button-catalog" href="https://www.multicoat.com/addditional-resources/#detail-drawings">Waterproofing Detail Drawings</a></p>
            <a id="copyright" class="btn btn-link show-for-large" href="http://maprehend.com/" title="Maprehend">
                <b>Material Estimator</b> created and powered by <b>Maprehend, LLC</b>
            </a>
        </div>
        <div id="footer2" class="sidebar footer small-12 medium-3 large-3 cell" role="complementary">
            <p><strong>East Coast</strong></p>
            <p>Multicoat Products<br>
                #1 Putnam Business Park Drive<br>
                Fraziers Bottom, WV 25082</p>
            <p>1-800-660-6729</p>
            <p>Fax: (304)937-2109</p>
            <p>info@multicoat.com</p>
        </div>
        <div id="footer3" class="sidebar footer small-12 medium-3 large-3 cell" role="complementary">
            <p><strong>West Coast</strong></p>
            <p>Multicoat Corporation<br>
                23331 Antonio Parkway<br>
                Rancho Santa Margarita, CA 92688</p>
            <p>1-877-685-8426</p>
            <p>Fax: (949)888-2555</p>
            <p>info@multicoat.com</p>
            <a id="copyright-mobile" class="btn btn-link show-for-small-only" href="http://maprehend.com/" title="Maprehend">
                <b>Material Estimator</b> created and powered by <br><b>Maprehend, LLC</b>
            </a>
        </div>
    </div> <!-- end #inner-footer -->
</footer>
