import { Component, Prop, Vue } from 'vue-property-decorator'
import WithRender from './profile_bar.html'
import './profilebar.scss'
import { AuthService } from "@/services/AuthService";

@WithRender
@Component
export class ProfileBarComponent extends Vue {
    protected authService: AuthService

    user = {
        full_name: '',
        is_authificated: false,
        id: ''
    }

    constructor() {
        super()

        this.authService = new AuthService()

        this.user.is_authificated = this.authService.isAuthificated()

        if (window.location.href.indexOf('projects') !== -1) {
            if (! this.user.is_authificated) {
                window.location.href = '/'
            }
        }

        let user = this.authService.user();

        this.user.full_name = user.name
        this.user.id = user.sub
    }

    get isAuthentication() : boolean {
        return this.user.is_authificated;
    }

    get nameIcon() : string {
        return this.user.full_name.split(' ').map(namePart => {
            return namePart.substring(0, 1).toLocaleUpperCase()
        }).join('')
    }

    isAdmin() : boolean {
        let vm = this

        return process.env.VUE_APP_ADMIN_IDS.split(',').map(function (id: string) {
            return id.trim()
        }).filter(function (id: any) {
            return id == vm.user.id
        }).length > 0
    }

    protected logout() {
        this.authService.logout()
    }
}
