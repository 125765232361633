import { Component, Prop, Vue } from 'vue-property-decorator'
import WithRender from './pagination.html'

@WithRender
@Component
export class PaginationComponent extends Vue {
    @Prop() private totalPages!: number|0
    @Prop() private currentPage!: number|0
    @Prop() private totalItems!: number|0
    @Prop() private itemsPerPage!: number|0

    get total() {
        return this.totalPages
    }

    get current() {
        return this.currentPage
    }

    get displayPagination() {
        return this.totalItems > this.itemsPerPage
    }

    protected nextPage() {
        this.$emit('pagination', this.currentPage + 1)
    }

    protected prevPage() {
        this.$emit('pagination', this.currentPage - 1)
    }
}