import axios, { AxiosInstance } from 'axios';

export class ProjectsService {

    protected http: AxiosInstance;

    constructor () {

        let idToken = localStorage.getItem('id_token');

        this.http = axios.create({
            baseURL: process.env.VUE_APP_PROJECTS_URL + '/projects/v0.0.1/',
            headers: {
                Authorization: 'Bearer ' + idToken
            }
        });
    }

    public async getProjects(args: object) {
        return await this.http.get('/', {params: args});
    }

    public async deleteProject(projectId: string) {
        return await this.http.get('/' + projectId + '/delete');
    }
}