
<div class="app-header header-wrapper2 ">
    <header class="header header--secondary">
        <div class="container">
            <div class="header_inner">
                <div class="header_head">
                    <div class="dropdown dropdown-quote">
                        <a v-show="user.is_authificated" href="/projects" class="btn dropdown-toggle" type="button">My Projects</a>
                    </div>
                </div>
                <nav class="nav-access">
                    <a v-show="! isAuthentication" href="#" class="login">Sign In</a>
                    <div v-show="isAuthentication" class="user-acct default">
                        <ul class="user-name">
                            <li class="dropdown default">
                                <span class="text">{{ user.full_name }}</span>

                                <a href="#" data-toggle="dropdown" role="button" aria-expanded="false">
                                    <span class="icon">{{ nameIcon }}</span>
                                </a>
                                <ul class="dropdown-menu" role="menu">
                                    <li>
                                        <a class="menu-item" href="/projects"><i class="fa fa-btn fa-paperclip"></i> My Projects</a>
                                    </li>
                                    <li>
                                        <a v-show="isAdmin" class="menu-item" href="/analytics"><i class="fa fa-btn fa-paperclip"></i> Analytics</a>
                                    </li>
                                    <li>
                                        <a @click="logout"><i class="fa fa-btn fa-sign-out-alt"></i> Logout</a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div class="user-acct bom hidden">

                        <ul class="user-name">
                            <li class="dropdown default">
                                <span class="text">{{ user.full_name }}</span>

                                <a href="#" data-toggle="dropdown" role="button" aria-expanded="false">
                                    <span class="icon">{{ nameIcon }}</span>
                                </a>
                                <ul class="dropdown-menu" role="menu">
                                    <li>
                                        <a class="menu-item" href="/projects"><i class="fa fa-btn fa-paperclip"></i> My Projects</a></li>
                                    <li>
                                        <a @click="logout"><i class="fa fa-btn fa-sign-out-alt"></i> Logout</a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </nav>
                <!-- /.nav-access -->
            </div>
            <!-- /.header_inner -->
        </div>
    </header>
    <!-- /.header -->
</div>

