<div>
    <header class="header">
        <div class="top-bar" id="top-bar-menu">
            <div class="top-bar-left float-left">
                <ul class="menu">
                    <li><a href="https://multicoat.com"><img src="https://sfo2.digitaloceanspaces.com/cdn-multicoat-com/wp-content/uploads/2018/08/02232141/logo.png" alt="" id="logo"></a></li>
                </ul>
            </div>
            <div class="top-bar-right float-right buttons show-for-large">
                <a href="https://configurator.multicoat.com/" class="button-configurator">Material Estimator</a>
            </div>
            <div class="top-bar-right float-right buttons">
                <a href="https://multicoat.com/contact/" class="button-contact"><i class="fas fa-envelope"></i></a>
                <a href="#" class="button-search"><i class="fas fa-search"></i></a>
                <div id="show-search">
                    <form role="search" method="get" class="search-form" action="https://multicoat.com/?s=">
                        <label>
                            <input type="search" class="search-field" placeholder="Search..." value="" name="s" title="Search for:">
                        </label>
                        <input type="submit" class="search-submit button" value="Search">
                    </form>
                </div>
            </div>
            <div class="top-bar-right float-right">
                <ul class="menu">
                    <li><a id="show-menu">Main Menu</a></li>
                </ul>
            </div>
        </div>
    </header>
    <div class="off-canvas-wrapper">
        <div class="off-canvas position-right is-transition-push is-closed" id="off-canvas">
            <div id="top-small-nav">
                <i class="fa fa-times" aria-hidden="true"></i>
            </div>
            <ul id="menu-main-menu" class="vertical menu accordion-menu" data-accordion-menu="" role="tree" aria-multiselectable="true" data-e="hqfoxm-e">
                <li id="menu-item-12" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-12" role="treeitem"><a href="https://multicoat.com/">Home</a></li>
                <li id="menu-item-4387" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-4387 active" role="treeitem"><a href="https://configurator.multicoat.com/">Material Estimator</a></li>
                <li id="menu-item-17" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-17" role="treeitem"><a href="https://multicoat.com/about/">About</a></li>
                <li id="menu-item-172" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-172 is-accordion-submenu-parent">
                    <a class="collapsed" data-toggle="collapse" href="#our-systems" aria-expanded="false" aria-controls="our-systems">Our Systems</a>
                    <ul class="vertical menu submenu is-accordion-submenu collapse" id="our-systems">
                        <li id="menu-item-665" class="menu-item menu-item-type-taxonomy menu-item-object-systems menu-item-665 is-submenu-item is-accordion-submenu-item" role="treeitem"><a href="https://multicoat.com/systems/concrete-restoration/">Concrete Restoration</a></li>
                        <li id="menu-item-664" class="menu-item menu-item-type-taxonomy menu-item-object-systems menu-item-664 is-submenu-item is-accordion-submenu-item" role="treeitem"><a href="https://multicoat.com/systems/waterproofing/">Waterproofing</a></li>
                        <li id="menu-item-3836" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-3836 is-submenu-item is-accordion-submenu-item" role="treeitem"><a href="https://multicoat.com/systems/ponds-and-water-features/">Pond, Water Feature, &amp; Fish Hatchery</a></li>
                        <li id="menu-item-667" class="menu-item menu-item-type-taxonomy menu-item-object-systems menu-item-667 is-submenu-item is-accordion-submenu-item" role="treeitem"><a href="https://multicoat.com/systems/pool-restoration/">Pool Restoration</a></li>
                        <li id="menu-item-669" class="menu-item menu-item-type-taxonomy menu-item-object-systems menu-item-669 is-submenu-item is-accordion-submenu-item" role="treeitem"><a href="https://multicoat.com/systems/shower-pans/">Shower &amp; Bathroom</a></li>
                        <li id="menu-item-666" class="menu-item menu-item-type-taxonomy menu-item-object-systems menu-item-666 is-submenu-item is-accordion-submenu-item" role="treeitem"><a href="https://multicoat.com/systems/stucco/">Stucco</a></li>
                        <li id="menu-item-668" class="menu-item menu-item-type-taxonomy menu-item-object-systems menu-item-668 is-submenu-item is-accordion-submenu-item" role="treeitem"><a href="https://multicoat.com/systems/thin-sets/">Thin Sets (Mortar)</a></li>
                    </ul>
                </li>
                <li id="menu-item-173" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-173 is-accordion-submenu-parent">
                    <a class="collapsed" data-toggle="collapse" href="#our-products" aria-expanded="false" aria-controls="our-products">Our Products</a>
                    <ul class="vertical menu submenu is-accordion-submenu collapse" id="our-products">
                        <li id="menu-item-661" class="menu-item menu-item-type-taxonomy menu-item-object-products menu-item-661 is-submenu-item is-accordion-submenu-item" role="treeitem"><a href="https://multicoat.com/products/decorative-cements/">Decorative Cements</a></li>
                        <li id="menu-item-658" class="menu-item menu-item-type-taxonomy menu-item-object-products menu-item-658 is-submenu-item is-accordion-submenu-item" role="treeitem"><a href="https://multicoat.com/products/waterproofing/">Waterproofing</a></li>
                        <li id="menu-item-660" class="menu-item menu-item-type-taxonomy menu-item-object-products menu-item-660 is-submenu-item is-accordion-submenu-item" role="treeitem"><a href="https://multicoat.com/products/paints-stains-and-sealers/">Paints, Stains, and Seals</a></li>
                        <li id="menu-item-659" class="menu-item menu-item-type-taxonomy menu-item-object-products menu-item-659 is-submenu-item is-accordion-submenu-item" role="treeitem"><a href="https://multicoat.com/products/bag-mixes/">Bag Mixes</a></li>
                        <li id="menu-item-663" class="menu-item menu-item-type-taxonomy menu-item-object-products menu-item-663 is-submenu-item is-accordion-submenu-item" role="treeitem"><a href="https://multicoat.com/products/stucco/">Stucco</a></li>
                        <li id="menu-item-662" class="menu-item menu-item-type-taxonomy menu-item-object-products menu-item-662 is-submenu-item is-accordion-submenu-item" role="treeitem"><a href="https://multicoat.com/products/tools/">Tools</a></li>
                    </ul>
                </li>
                <li id="menu-item-3100" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-3100 is-accordion-submenu-parent">
                    <a class="collapsed" data-toggle="collapse" href="#additional-resources" aria-expanded="false" aria-controls="additional-resources">Additional Resources</a>
                    <ul class="vertical menu submenu is-accordion-submenu collapse" id="additional-resources">
                        <li id="menu-item-3104" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-3104 is-submenu-item is-accordion-submenu-item" role="treeitem"><a target="_blank" rel="noopener noreferrer" href="http://development.multicoat.com/addditional-resources">Overview</a></li>
                        <li id="menu-item-3101" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-3101 is-submenu-item is-accordion-submenu-item" role="treeitem"><a target="_blank" rel="noopener noreferrer" href="http://development.multicoat.com/addditional-resources/#color-charts">Color Charts</a></li>
                        <li id="menu-item-3102" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-3102 is-submenu-item is-accordion-submenu-item" role="treeitem"><a target="_blank" rel="noopener noreferrer" href="http://development.multicoat.com/addditional-resources/#detail-drawings">Detail Drawings</a></li>
                        <li id="menu-item-3103" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-3103 is-submenu-item is-accordion-submenu-item" role="treeitem"><a target="_blank" rel="noopener noreferrer" href="http://development.multicoat.com/addditional-resources/#arch-specs">Arch Specs</a></li>
                        <li id="menu-item-3106" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-3106 is-submenu-item is-accordion-submenu-item" role="treeitem"><a target="_blank" rel="noopener noreferrer" href="http://development.multicoat.com/resources/">MSDS/SDS</a></li>
                    </ul>
                </li>
                <li id="menu-item-3769" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-3769" role="treeitem"><a href="https://multicoat.com/2019/04/11/terms-conditions/">Terms &amp; Conditions</a></li>
                <li id="menu-item-3794" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-3794" role="treeitem"><a href="https://multicoat.com/2019/04/11/warranty-information/">Warranty Information</a></li>
                <li id="menu-item-22" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-22" role="treeitem"><a href="https://multicoat.com/contact/">Contact Us</a></li>
            </ul>
        </div>
    </div>
</div>
