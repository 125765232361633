 <div>
     <div class="container">
        <!--<h1 class="section_title">Welcome back, {{ userName }}!</h1>--><!-- /.section-title -->

        <div class="section_body">
            <div id="projects">
                <div class="section_bar">

                    <div class="dropdown dropdown-sort">
                        <button class="sort-dropdown dropdown-toggle" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                            <span class="hidden-xs">View by: </span>
                            <span class="sort-by">{{ selectedSortingMethod }}</span>
                            <i class="ico-angle-down"></i>
                        </button>

                        <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
                            <li>
                                <span class="link-false">Sort by:</span>
                            </li>
                            <li role="separator" class="divider"></li>
                            <li>
                                <button class="sort" @click="updateSortingMethod('alphabetical')">
                                    <i class="fa fa-check"></i>

                                    <span class="text">Alphabetical</span>
                                </button>
                            </li>
                            <li>
                                <button class="sort" @click="updateSortingMethod('last')">
                                    <i class="fa fa-check"></i>

                                    <span class="text">Last Updated</span>
                                </button>
                            </li>
                            <li>
                                <button class="sort" @click="updateSortingMethod('system_size')">
                                    <i class="fa fa-check"></i>

                                    <span class="text">System Size</span>
                                </button>
                            </li>
                        </ul>
                    </div>

                    <div :class="{ open: isSearchOpen }" class="search-filter" >
                        <div class="search-filter_content">
                            <form class="form-search" action="?" method="post">
                                <a href="#" class="search-filter_action search-filter_trigger" @click="isSearchOpen = !isSearchOpen">
                                    <i class="ico-search"></i>
                                </a>

                                <button type="reset" class="search-filter_action search-filter_close" @click="resetSearch()">
                                    <i class="ico-close"></i>
                                </button>

                                <input class="search" type="text" name="search" v-model="search.query" placeholder="Search Projects" id="search-tags" v-on:input="doSearch(true)" />
                            </form>
                        </div><!-- /.search-filter_content -->
                    </div><!-- /.search-filter -->

                    <div class="dropdown dropdown-project">
                        <button class="hidden-xs btn dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                            <span class="text">Create New Project</span>

                            <i class="ico-angle-down-white hidden-xs"></i>

                            <span class="ico-plus-color visible-xs-inline-block"></span>
                        </button>

                        <!-- Button trigger modal -->
                        <button type="button" class="visible-xs-inline-block btn dropdown-toggle" data-toggle="modal" data-target="#myModal">
                            <span class="ico-plus-color"></span>
                        </button>

                        <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
                            <li>
                                <span class="link-false">Which kind?</span>
                            </li>

                            <li role="separator" class="divider"></li>

                            <li>
                                <a href="/waterproofing-and-water-features/new">Waterproofing & Water Features</a>
                            </li>
                            <li>
                                <a href="/decorative-concrete-overlays/new">Decorative Concrete Overlay</a>
                            </li>
                        </ul>
                    </div>
                </div><!-- /.section_bar -->

                <table class="table-projects">
                    <thead>
                    <tr>
                        <th>Project Name</th>
                        <th>Type</th>
                        <th>Last Modified</th>
                        <th>Summary</th>
                        <th>&nbsp;</th>
                    </tr>
                    </thead>

                    <tbody class="list">

                    <tr v-for="project in projects" :key="project.id">
                        <td class="name">
                            <a :href="project.link"><strong><span class='projname'>{{ project.name }}</span></strong></a>

                            <small>ID: {{ project.id }}</small>
                        </td>

                        <td class="type">{{ project.type }}</td>
                        <td class="lastupdated">{{ project.last_modified }}
                            <span class="lastupdated hidden">{{ project.last_modified }}</span>
                        </td>

                        <td class="summary">
                            <!-- {% if project.itemCnt > 0  %} -->
                            <div v-if="project.itemCount > 0">
                                <div>Items:
                                    <span class="mostitems">5</span>
                                </div>
                                <div>System Size: <span class="wattage">250</span></div>
                            </div>

                            <!-- {% else %} -->
                            <div v-else>In process<span class="wattage hidden">100</span> </div>
                            <!-- {% endif %} -->
                        </td>
                        <!-- Project Delete Button -->
                        <td>
                            <button id="delete-project-projectId" class="btn btn-danger" @click="deleteProject(project.id)" style="color:#c5c3c3;background-color:transparent;border:0px;">
                                <i class="fa fa-btn btn-sm fa-trash"></i>
                            </button>
                        </td>
                    </tr>

                    </tbody>
                </table>

                <pagination @pagination="onPagination" :total-pages="paginationSettings.totalPages" :current-page="paginationSettings.currentPage" :total-items="paginationSettings.totalItems" :items-per-page="paginationSettings.itemsPerPage"></pagination>
            </div>

            <div class="section_content">
                <h3>Perhaps the project doesn’t exist yet. <br />Shall we get it started?</h3>

                <div class="section_actions">
                    <a href="" class="btn btn-solid btn-block">Yes, let&rsquo;s create a new project.</a>

                    <a href="" class="btn btn-transparent btn-block">Nah, let&rsquo;s just go back to my projects.  </a>
                </div><!-- /.section_actions -->
            </div><!-- /.section_content -->
        </div><!-- /.section_body -->
    </div><!-- /.container -->
 </div>
