<template>
  <div id="app">
    <projects></projects>
  </div>
</template>

<style lang="scss">
    @import "styles/sunmodo/sunmodo-styles.scss";
    #app{
        font-family:  $app-font;
        color: $app-color;
        background-color: $app-background-color;
        font-weight: normal;
        font-style: normal;
    }
</style>

<script>
  // @ is an alias to /src
  import Projects from '@/views/Projects.vue'

  export default {
    title: 'dd',
    components: {
      projects: Projects
    }
  }
</script>
