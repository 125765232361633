import { Component, Prop, Vue } from 'vue-property-decorator';
import {ProjectsService} from '../../services/ProjectsService'
import './projects.scss'
import WithRender from './projects.html'
import {PaginationComponent} from '../PaginationComponent'
import {AuthService} from "@/services/AuthService";

@WithRender
@Component({
    components: {
        pagination: PaginationComponent
    }
})
export class ProjectsComponent extends Vue {
  @Prop() private userName!: string;

  protected isSearching = false

  protected sortingTypes = [
      {
          title: 'Alphabetical',
          value: 'alphabetical'
      },
      {
          title: 'Last Updated',
          value: 'last'
      },
      {
          title: 'System Size',
          value: 'system_size'
      }
  ]

  protected paginationSettings = {
      totalPages: 0,
      currentPage: 0,
      totalItems: 0,
      itemsPerPage: 0
  }

  protected search = {
      query: '',
      sort: 'last',
      page: 1
  }

  protected projectsService: ProjectsService
  protected authService: AuthService

  protected projects: Array <object>;

  public isSearchOpen: boolean = false;

  constructor() {
      super()
      this.projects = [];
      this.projectsService = new ProjectsService()
      this.authService = new AuthService()
  }

  protected updateSortingMethod(sortingType: string) {
      let isValidSorting = this.sortingTypes.filter(function (sortingOption: any) {
          return sortingOption.value === sortingType
      }).length > 0

      if (isValidSorting) {
          this.search.page = 1
          this.search.sort = sortingType
          this.doSearch()
      }
  }

  get selectedSortingMethod() {
      let vm = this
      let sortingMethod = this.sortingTypes.filter(function (sortingType: any) {
          return sortingType.value === vm.search.sort
      }).pop()

      if (! sortingMethod) {
          return '';
      }

      return sortingMethod.title
  }

  protected onPagination(page: number) {
      if (! page) {
          page = 1
      }

      if (page > this.paginationSettings.totalPages) {
          page = this.paginationSettings.totalPages
      }

      this.search.page = page
      this.doSearch()
  }

  protected resetSearch()
  {
      this.isSearchOpen = !this.isSearchOpen

      if (this.isSearchOpen === false) {
          this.search.query = ''
          this.search.page = 1
          this.doSearch()
      }
  }

  protected deleteProject(projectId: string) {
        let vm = this

        this.projectsService.deleteProject(projectId).then(function () {
            vm.projects.forEach(function (project: any, index) {
                if (project.id === projectId) {
                    vm.projects.splice(index, 1)

                    //vm.paginationSettings.totalItems - vm.paginationSettings.itemsPerPage

                    vm.doSearch()
                }
            })
        })
  }

  protected doSearch(isNewSearch?: boolean) {
      if (isNewSearch) {
          this.search.page = 1
      }

      if (this.isSearching === false && (this.search.query.length == 0 || this.search.query.length > 2)) {
          this.isSearching = true

          let vm = this

          this.projectsService.getProjects(this.search).then(function (response) {

              vm.projects.forEach(function (project, index) {
                  vm.projects.splice(index, 50)
              })

              response.data.data.forEach(function (project: any) {
                  project.link = '/project/' + project.id + '?token=' + localStorage.getItem('id_token')
                  vm.projects.push(project)
              })
              vm.paginationSettings.totalPages = response.data.meta.pagination.total_pages
              vm.paginationSettings.currentPage = response.data.meta.pagination.current_page
              vm.paginationSettings.totalItems = response.data.meta.pagination.total
              vm.paginationSettings.itemsPerPage = response.data.meta.pagination.per_page
              vm.isSearching = false
          }).catch(error => {

          })
      }
  }

  protected mounted () {
      this.doSearch()
  }
}
