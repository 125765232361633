import './jquery'
import Vue from 'vue'
import App from './App.vue'
import {HeaderComponent} from '@/components/HeaderComponent'
import {FooterComponent} from '@/components/FooterComponent'
import {ProfileBarComponent} from '@/components/ProfileBarComponent'
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

import Projects from '@/views/Projects.vue'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

import router from './router'

Vue.config.errorHandler = (msg, vm , info) => {
  alert(info)
}

Sentry.init({
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [new Integrations.Vue({Vue, attachProps: true, logErrors: true})],
});

Vue.config.productionTip = false

new Vue({
 // router,
  render: h => h(HeaderComponent)
}).$mount('#header')

new Vue({
  // router,
  render: h => h(FooterComponent)
}).$mount('#footer')

new Vue({
  // router,
  render: h => h(ProfileBarComponent)
}).$mount('#profileBar')


new Vue({
  // router,
  render: h => h(App)
}).$mount('#app')
