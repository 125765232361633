import Auth0Lock from 'auth0-lock';
import decode from 'jwt-decode';
import * as Sentry from '@sentry/browser';

export class AuthService {

    protected lock: any

    constructor () {
        this.lock = new Auth0Lock('wBn5c0yKsiaVQFccof2vw5fg4Y5ED2oi', 'dev-maprehend.auth0.com', {
            auth : {
                responseType : 'id_token',
                redirect: true
            },
            rememberLastLogin  : false,
            loginAfterSignUp   : false,
            avatar             : null,
            theme: {
                logo: '',
                primaryColor: '#45959e'
            },
            languageDictionary : {
                title: 'Sign In',
                forgotPasswordTitle: ''
            }
        })
    }

    public async logout() {
        localStorage.removeItem('id_token')
        localStorage.removeItem('token_expire')
        localStorage.removeItem('access_token')

        window.location.href = '/'
    }

    public getLock() {
        return this.lock
    }

    public isAuthificated() : boolean {

        let authStatus = false

        let token : string|null = localStorage.getItem('id_token')

        if (token != null && token.length > 0) {
            try {
                let decodedToken : any = decode(token)
                let ts = Math.round((new Date()).getTime() / 1000)

                if ((decodedToken.exp - ts) <= 0) {
                    return false
                }

                return true
            } catch (e) {
                Sentry.captureMessage(e);
                Sentry.setContext('token', {token: token})
                return false
            }
        }

        return authStatus
    }

    public user() {

        let user = {
            name: '',
            sub: ''
        }

        if (! this.isAuthificated()) {
            return user
        }

        const token : any = localStorage.getItem('id_token');

        if (token) {
            user = decode(token)
        }

        return user;
    }
}