<div v-show="displayPagination" class="pagination-wrapper">

    <a v-show="current > 1" class="pagination-arrow pagination-arrow-prev" href="#" @click.prevent="prevPage()">
        <i class="ico-page-left"></i>
    </a>

    <ul class="pagination">
        <li class="active">
            <b class="pagination-items">{{ total }}</b>
            <a href="#" class="page">{{ current }}</a>
        </li>
    </ul>

    <a v-show="current < total" class="pagination-arrow pagination-arrow-next" href="#" @click.prevent="nextPage()">
        <i class="ico-page-next"></i>
    </a>
</div><!-- /.pagination-wrapper -->