<template>
    <div>
        <headerComponent></headerComponent>
        <div class="wrapper wrapper--tertiary">
            <profileBarComponent></profileBarComponent>
            <section class="section">
                <projects-component user-name="Nadiia"></projects-component>
            </section>
            <footerComponent></footerComponent>
        </div>
    </div>

</template>

<script>
// @ is an alias to /src
import {ProjectsComponent} from '@/components/ProjectsComponent'
import {FooterComponent} from '@/components/FooterComponent'
import {HeaderComponent} from '@/components/HeaderComponent'
import {ProfileBarComponent} from '@/components/ProfileBarComponent'
import '../styles/projects-base.scss'

export default {
  name: 'projects',
  components: {
      ProjectsComponent,
      headerComponent: HeaderComponent,
      footerComponent: FooterComponent,
      profileBarComponent: ProfileBarComponent
  }
}
</script>



